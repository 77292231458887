import React, { memo, useState } from 'react';

import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '~/components/SvgIcon';
import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const StyledSearch = styled(SearchField)`
  height: 32px;
  width: 248px;
  margin-bottom: 8px;
`;

const TabsContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ${(props) =>
    props.$blocksStyle &&
    `
  align-items: center;
  `};
`;

const Tab = styled.li`
  color: ${(props) => (props.$isSelected ? 'var(--company-color)' : COLORS.TEXT_SECONDARY)};
  font-weight: ${(props) => (props.$isSelected ? '600' : 'normal')};
  border-bottom: ${(props) =>
    props.$isSelected ? '2px solid var(--company-color)' : '2px solid transparent'};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-right: ${(props) => props.$margin || '24px'};
  padding-bottom: 12px;
  font-size: ${(props) => (props.$isSmall ? '16px' : '18px')};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => (props.$isSmall ? 'normal' : '1.38')};
  cursor: pointer;
  margin-bottom: -1px;
  transition: 0.15s;
  span {
    margin-left: ${(props) => props.$isIcon && '8px'};
  }

  div {
    white-space: nowrap;
  }
  ${({ $blocksStyle, isLast, isFirst }) =>
    $blocksStyle &&
    `
  border-top: solid 1px ${COLORS.BORDER_HARD};
  border-bottom: solid 1px ${COLORS.BORDER_HARD};
  border-left: solid 1px ${COLORS.BORDER_HARD};
  border-right: ${isLast ? `solid 1px ${COLORS.BORDER_HARD}` : 'none'};
  border-top-left-radius: ${isFirst ? '6px' : '0px'};
  border-bottom-left-radius: ${isFirst ? '6px' : '0px'};
  border-top-right-radius: ${isLast ? '6px' : '0px'};
  border-bottom-right-radius: ${isLast ? '6px' : '0px'};
  padding: 6px 14px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  `};
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 0 24px;
  margin: 0;
  margin-bottom: ${(props) => (!props.$noMarginBottom ? 0 : props.$isSmall ? '16px' : '24px')};
  padding-left: ${(props) => props.$isSmall && '24px'};
  border-bottom: ${(props) =>
    !props.$noBorder &&
    (props.$borderColor ? `1px solid ${props.$borderColor}` : `1px solid ${COLORS.BORDER_HARD}`)};
  height: 50px;
  align-items: flex-end;
  justify-content: space-between;

  ${({ $blocksStyle }) =>
    $blocksStyle &&
    `
    height: auto;
    padding: 18px 18px 16px 18px;
    border-bottom: solid 1px ${COLORS.BORDER_LIGHT};
    margin-bottom: 24px;
  `};
`;

const Tabs = ({
  i18n,
  items,
  currentItem,
  isSmall,
  handleChangeTab,
  className,
  actions,
  marginBetweenTabs,
  noMarginBottom,
  isSearch,
  onChangeSearch,
  borderColor,
  noBorder,
  rightBlock,
  isBlocksStyle,
  wrapperStyles,
}) => {
  const [search, setSearch] = useState('');
  const handleSearch = (e) => {
    const text = e.target.value || '';
    setSearch(text);
    onChangeSearch(
      (item) => item && (item.name || '').toLowerCase().indexOf(text.toLowerCase()) !== -1,
    );
  };

  return (
    <Wrapper
      $borderColor={borderColor}
      $noBorder={noBorder}
      $blocksStyle={isBlocksStyle}
      style={wrapperStyles}
    >
      <TabsContainer
        $blocksStyle={isBlocksStyle}
        $isSmall={isSmall}
        className={className}
        $noMarginBottom={noMarginBottom}
      >
        {map(items || [], (item, index) => (
          <Tab
            $margin={marginBetweenTabs}
            noBorder={true}
            key={item.key}
            $isSelected={currentItem === item.key}
            onClick={item.action ? item.action : () => handleChangeTab(item.key)}
            $isIcon={item.icon}
            $isSmall={isSmall}
            isFirst={index === 0}
            isLast={index === items.length - 1}
            $blocksStyle={isBlocksStyle}
          >
            {item.icon && (
              <SvgIcon
                width="20px"
                height="20px"
                url={item.icon}
                defaultColor={COLORS.TEXT_PRIMARY_2}
                isDefaultColor={currentItem !== item.key}
              />
            )}
            <div>{typeof item.label === 'string' ? i18n._(item.label) : item.label(i18n)}</div>
          </Tab>
        ))}
        {actions && actions}
      </TabsContainer>
      {isSearch && (
        <StyledSearch placeholder={i18n._(t`Search`)} value={search} onChange={handleSearch} />
      )}
      {rightBlock}
    </Wrapper>
  );
};

Tabs.defaultProps = {
  noMarginBottom: true,
};

Tabs.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  currentItem: PropTypes.string,
  handleChangeTab: PropTypes.func,
  className: PropTypes.string,
  actions: PropTypes.node,
  noMarginBottom: PropTypes.bool,
  rightBlock: PropTypes.node,
};

export default memo(withI18n()(Tabs));
